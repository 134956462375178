<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../assets/cds/banner.png" alt="" />
      <div class="banner-title">
        <!-- <img src="../../assets/cds/cds-icon.png" alt="">
        {{t("product.cds.banner_title")}} -->
        <h2>{{t("product.cds.banner_title")}}</h2>
        <h4>{{t("product.cds.banner_sub_title")}}</h4>
      </div>
      <div class="banner-message">
        <div class="banner-icon">
          <img src="../../assets/cds/cds.png" alt="">
        </div>
        <div class="banner-text">
          <div>{{ t("product.cds.banner_description") }}</div>
          <a class="banner-btn" href="#" target="_blank">{{
            t("home.banner_btn")
          }}</a>
        </div>
      </div>
    </div>
    <div class="info-box">
      <h1 class="info-title">{{ t("product.cds.data_pipeline") }}</h1>
      <div class="info-card info-card-img">
        <img class="img" src="../../assets/cds/pipeline.png" alt="" />
      </div>
    </div>
    <div class="no-bg">
      <div class="info-box">
        <h1 class="info-title">{{ t("product.cds.features") }}</h1>
        <div class="info-card">
          <div class="card">
            <img class="card-icon" src="../../assets/cds/features1.png" alt="" />
            <div class="card-content">
              <div class="card-title">{{ t("product.cds.features_title1") }}</div>
              <!-- <div class="card-text">{{ t("product.cds.features_dec1") }}</div> -->
            </div>
          </div>
          <div class="card">
            <img class="card-icon" src="../../assets/cds/features2.png" alt="" />
            <div class="card-content">
              <div class="card-title">{{ t("product.cds.features_title2") }}</div>
              <!-- <div class="card-text">{{ t("product.cds.features_dec2") }}</div> -->
            </div>
          </div>
          <div class="card">
            <img class="card-icon" src="../../assets/cds/features3.png" alt="" />
            <div class="card-content">
              <div class="card-title">{{ t("product.cds.features_title3") }}</div>
              <!-- <div class="card-text">{{ t("product.cds.features_dec3") }}</div> -->
            </div>
          </div>
          <div class="card">
            <img class="card-icon" src="../../assets/cds/features4.png" alt="" />
            <div class="card-content">
              <div class="card-title">{{ t("product.cds.features_title4") }}</div>
              <!-- <div class="card-text">{{ t("product.cds.features_dec4") }}</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Explain/>
    <HelpForm/>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import Explain from '../../components/Explain/index.vue';
import HelpForm from '../../components/HelpForm/index.vue';
export default {
    name: 'CDS',
    components: {Explain, HelpForm},
    setup() {
        const { t } = useI18n();
        return {
        t,
        };
    }
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 800px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #F4F5F5;
      // font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      // font-size: 62px;
      position: absolute;
      top: 50%;
      left: 22%;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
      img {
        display: block;
        margin-bottom: 30px;
      }
    }
    .banner-message {
      position: absolute;
      top: 75%;
      left: 50%;
      transform: translateX(-50%);
      width: 1140px;
      padding: 40px;
      margin: 0 auto;
      text-align: left;
      font-size: 16px;
      line-height: 26px;
      background: #ffffff;
      color: #333333;
      box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      display: flex;
      .banner-icon {
        margin-right: 35px;
      }
      .banner-text {
        position: relative;
      }
      .banner-btn {
        display: block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        background: #ff9333;
        border-radius: 24px;
        margin-top: 15px;
        float: right;
      }
    }
  }
  .info-box {
    max-width: 1140px;
    margin: 80px auto;
    text-align: center;
    padding: 20px 0;
    .info-title::after {
      content: "";
      display: block;
      width: 120px;
      text-align: center;
      margin: 20px auto 40px;
      background: #ff9333;
      border: 3px solid #ff9333;
      border-radius: 3px;
    }
    .info-card {
      display: flex;
      flex-wrap: wrap;
      &.info-card-img {
        display: block;
      }
      .card {
        // flex: 1;
        width: 550px;
        background: #ffffff;
        margin-right: 30px;
        margin-bottom: 30px;
        box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
        &:nth-child(2n) {
          margin-right: 0;
        }
        .card-icon {
          width: 40%;
          float: left;
          // margin-bottom: 16px;
        }
        .card-content {
          width: 60%;
          float: left;
          padding: 20px 30px;
          text-align: left;
        }
        .card-title {
          color: #102b47;
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 10px;
          line-height: 20px;
          text-align: center;
        }
        .card-text {
          // padding: 0 20px 20px;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 18px;
        }
      }
      .img {
        width: 100%;
      }
    }
  }
  .no-bg {
    background: #f1f1f1;
    .info-box {
      .info-card {
        .card {
          flex: 1;
          width: 25%;
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
          .card-icon {
            width: 100%;
            margin-bottom: 16px;
          }
          .card-content {
            width: 100%;
            padding: 20px 30px;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
